@font-face {
  font-family: 'Courier New';
  src: local('Courier New'), url(./assets/fonts/cour.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Courier New Italic';
  src: local('Courier New Italic'), url(./assets/fonts/couri.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/Proxima_Nova_Regular.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Proxima Nova Italic';
  src: local('Proxima Nova Italic'), url(assets/fonts/Proxima_Nova_Italic.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Fira Sans';
  src: local('Fira Sans'), url(./assets/fonts/FiraSans-Medium.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.fira-sans {
  font-family : Fira Sans
}
.proxima-nova {
  font-family: "Proxima Nova";
}
.proxima-nova-i {
  font-family: "Proxima Nova Italic";
}

.courier-new {
  font-family : Courier New
}

.courier-new-i {
  font-family : Courier New Italic
}
